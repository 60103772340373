// ** Icons Import
import { Home, Grid } from 'react-feather'
import dashbord from '@src/assets/images/NavigationIcons/dashboard.png'
export default [
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: <img src={ dashbord} style={{width: '25px', height: '25px', marginRight: '10px'}} />,
    badge: 'light-warning',
    navLink: '/dashboard',
    permissions: ['admin', 'any', 'masteradmin', 'customer']
  }
]
