// ** Dropdowns Imports
import IntlDropdown from './IntlDropdown'
// import CartDropdown from './CartDropdown'
import UserDropdown from './UserDropdown'
// import NavbarSearch from './NavbarSearch'
// import NotificationDropdown from './NotificationDropdown'
import LogRocket from 'logrocket'

// ** Third Party Components
import { Sun, Moon, HelpCircle } from 'react-feather'

// ** Reactstrap Imports
import { Col, Form, Modal, ModalBody, ModalHeader, Button, NavItem, NavLink, Row, Input, ModalFooter, Label } from 'reactstrap'
import { useEffect, useState } from 'react'
import { selectThemeColors } from '@utils'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'

// ** Azure Imports
import { EmailClient } from '@azure/communication-email'
import Email from './emailTemplate'
const connectionString = "endpoint=https://shipsmpl-sms.canada.communication.azure.com/;accesskey=jzXI6r0iIow+716C1pp6tWvnPaYHrGLP2b8flNrJQpXxLCyPfyexAxi3xcM+WJN5bMun3ZQG+NqALzc9G4Qs/Q=="
const emailClient = new EmailClient(connectionString)
// const userName = useSelector((state) => state.auth.userData.username)

const NavbarUser = props => {
  // ** Props
  const store = useSelector(state => state.auth)
  const { skin, setSkin } = props

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === 'dark') {
      return <div id='themeToggler'><Sun className='ficon' onClick={() => setSkin('light')} /></div>
    } else {
      return <div id='themeToggler'><Moon className='ficon' onClick={() => setSkin('dark')} /></div>
    }
  }

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [filterForm, setFilterForm] = useState({
    option: "",
    title: "",
    description: ""
  })

  const [open, setOpen] = useState(false)
  const toggle = () => {
    setFilterForm({
      option: "",
      title: "",
      description: ""
    })
    setOpen(!open)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (isSubmitting) return
    setIsSubmitting(true)

    const { firstName, lastName } = store.userData
    const { option, title, description } = filterForm
    const pageUrl = window.location.href

    const emailHtml = Email({
      user: `${firstName} ${lastName}`,
      option,
      title,
      description,
      page: pageUrl
    })

    const message = {
      senderAddress: "DoNotReply@shipsmpl.com",
      content: {
        subject: `Help Request - ${firstName} ${lastName}`,
        html: emailHtml
      },
      recipients: {
        to: [{ address: "support@wtcgroup.com", displayName: "ShipSmpl" }]
      }
    }

    const toastId = toast.loading("Submitting feedback...")

    try {
      const poller = await emailClient.beginSend(message)
      const response = await poller.pollUntilDone()
      if (response.status === "Succeeded") {
        toast.success("Feedback submitted successfully!", { id: toastId })
      } else {
        toast.error("Failed to submit feedback", { id: toastId })
      }

      // LogRocket feedback tracking
      const timestamp = new Date().toISOString()
      const fullName = `${firstName} ${lastName}`
      LogRocket.track('User Feedback', { feedback: description, timestamp, fullName, option, title, description, pageUrl })
      LogRocket.log(`Feedback submitted: ${description} at ${timestamp} by user ${fullName} with option ${option} and title ${title}`)

      toggle()
    } catch (error) {
      toast.error("An error occurred", { id: toastId })
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    const previousPage = localStorage.getItem("currentPage")
    localStorage.setItem("previousPage", previousPage || null)
    
    //set current page, current page should be just url without domain
    let currentPage = window.location.href
    currentPage = currentPage.replace(window.location.origin, "")
    localStorage.setItem("currentPage", currentPage)

  }, [window.location.href])

  return (
    <>
    <ul className='nav navbar-nav align-items-center ms-auto'>
      <IntlDropdown />
      <NavItem className='d-none d-lg-block'>
        <NavLink className='nav-link-style'>
          <ThemeToggler/>
        </NavLink>
      </NavItem>
      <NavItem className='nav-search' onClick={() => toggle()}>
      <NavLink className='nav-link-search'>
        <HelpCircle className='ficon' />
      </NavLink>
    </NavItem>
       {/* <NavbarSearch /> 
       <CartDropdown /> 
       <NotificationDropdown />  */}
      <UserDropdown />
    </ul>

      <Modal isOpen={open} toggle={toggle} className='modal-dialog-centered'>
        <ModalHeader toggle={toggle}>Help Function</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row className='mb-2'>
              <Col lg='12' className='mt-1'>
                <Label for='status'>Select an option <span className='text-primary'>*</span></Label>
                <Select
                  id='status'
                  isClearable={false}
                  className='react-select border rounded'
                  classNamePrefix='select'
                  options={['Report a bug', 'Provide a feedback', 'Ask a question'].map(i => ({ value: i, label: i})) }
                  theme={selectThemeColors}
                  value={{ value: filterForm.option, label: filterForm.option === "" ? "Select an option" : filterForm.option }}
                  onChange={(e) => setFilterForm({ ...filterForm, option: e.value })}
                />
              </Col>

              <Col lg='12' className='mt-1'>
                <Label for='title'>Title <span className='text-primary'>*</span></Label>
                <Input
                  type='text'
                  value={filterForm.title || ''}
                  onChange={e => setFilterForm({ ...filterForm, title: e.target.value })}
                  name='title'
                  placeholder='Title'
                />
              </Col>

              <Col lg='12' className='mt-1'>
                <Label for='description'>Description <span className='text-primary'>*</span></Label>
                <Input
                  type='textarea'
                  value={filterForm.description || ''}
                  onChange={e => setFilterForm({ ...filterForm, description: e.target.value })}
                  name='description'
                  placeholder='Description'
                />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={handleSubmit}>Submit</Button>
          <Button color='secondary' onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
export default NavbarUser
