// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'
import { urlAuthService, urlEquipmentService, urlReportingService, urlFreightForwardService, urlCargoInventoryService, urlMaterialInventoryService, urlConfigCacheService} from '../../../../endpoints'
import { getUriParams } from '@utils'

export const getStuffingReportStats = async (companyCode) => {
    const customerDashboardDTO = {
        fromDate: new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString(), // 6 months ago
        toDate: new Date().toISOString(),
        customerCode: companyCode
    }
    const response = await axios.post(`${urlReportingService}/GetStuffingReportStats`, customerDashboardDTO)
    return {
        data: response.data
    }
}

// Transloading Tab
export const getTransloadingList = createAsyncThunk('customerDashboard/getTransloadingList', async (data) => {
    const { params, filter } = data

    const uri = getUriParams(params)
    const filterData = {
        customerName: JSON.parse(localStorage.getItem("userData")).company, // Customer Code
        isValid: true, // Always set to true
        oceanBookingNumber: filter.bookingNumber || "",
        customerReference: filter.customerReference || "",
        shipperReference: filter.shipperReference || "",
        vesselName: filter.vesselName || "",
        voyageNumber: filter.voyageNumber || "",
        destination: filter.destination || "",
        shippingLineCode: filter.line || "",
        lrdFrom: filter.lrdFrom || null,
        lrdTo: filter.lrdTo || null
    }

    const response = await axios.post(`${urlReportingService}/GetAllTransloadBookingReportList?${uri}`, filterData)
    return {
        params,
        data: response.data.data,
        totalPages: response.data.totalCount,
        filterData
    }
})

// Freight Forwarding Tab
export const getFFOrderList = createAsyncThunk('customerDashboard/getFFOrderList', async (data) => {
    const { params, filter } = data

    const uri = getUriParams(params)
    const filterData = {
        companyCode: JSON.parse(localStorage.getItem("userData")).companyCode, // Customer Code
        ffNumber: filter.ffNumber || "",
        oceanBookingNumber: filter.bookingNumber || "",
        shipperRefNumber: filter.shipperReference || "",
        source: filter.source || "",
        destination: filter.destination || "",
        vesselName: filter.vesselName || "",
        voyageNumber: filter.voyageNumber || "",
        fromETDPortOfLoading: filter.frometd || null,
        toETDPortOfLoading: filter.toetd || null,
        fromETAPortOfDestination: filter.frometa || null,
        toETAPortOfDestination: filter.toeta || null
    }
    const response = await axios.post(`${urlFreightForwardService}/GetAllFFRequest?${uri}`, filterData)
    return {
        params,
        data: response.data.data,
        totalPages: response.data.totalCount,
        filterData
    }
})

// Cargo Inventory Tab
export const getCargoInventory = createAsyncThunk('customerDashboard/getCargoInventory', async (data) => {
    const { params, filter } = data

    const uri = getUriParams(params)
    const filterData = {
        isActive: true, // Always set to true
        owner: JSON.parse(localStorage.getItem("userData")).companyCode,
        commodityType: filter.commodityType || "",
        commodityName: filter.commodityName || "",
        commodityGrade: filter.commodityGrade || "",
        lotNumber: filter.lotNumber || "",
        packagingType: filter.packagingType || "",
        yardLocation: filter.yardLocation || "",
        locationType: filter.locationType || "",
        fromDateIn: filter.fromDateIn || null,
        toDateIn: filter.toDateIn || null
    }
    const response = await axios.post(`${urlCargoInventoryService}/GetAllCargoInventory?${uri}`, filterData)
    return {
        params,
        data: response.data.data,
        totalPages: response.data.totalCount,
        filterData
    }
})

// Material Inventory Tab
export const getMaterialInventory = createAsyncThunk('customerDashboard/getMaterialInventory', async (data) => {
    const { params, filter } = data

    const uri = getUriParams(params)
    const filterData = {
        isActive: true, // Always set to true
        owner: JSON.parse(localStorage.getItem("userData")).companyCode,
        type: "PACKAGING",
        item: filter.item || "",
        yardLocation: filter.yardLocation || null,
        locationType: filter.locationType || null,
        locationCode: filter.locationCode || "",
        reservedItems: filter.reservedItems || null
    }
    const response = await axios.post(`${urlMaterialInventoryService}/GetAllMaterialInventory?${uri}`, filterData)
    return {
        params,
        data: response.data.data,
        totalPages: response.data.totalCount,
        filterData
    }
})

// ** USE FOR - Inbound Cargo Tab
export const getScheduleTerminalData = createAsyncThunk('customerDashboard/getScheduleTerminalData', async (data) => {
    const { params, filter } = data

    const uri = getUriParams(params)
    const filterData = {
        movementType: "schedule", // Customer Dashboard only to show scheduled movement
        yardLocation: filter.terminal || "ALL",
        customerName: JSON.parse(localStorage.getItem("userData")).companyCode, // Customer Code
        line: filter.line || "",
        referenceNumber: filter.referenceNumber || "",
        equipmentStatus: filter.equipmentStatus || "",
        equipmentNumber: filter.equipmentNumber || "",
        commodityType: filter.commodityType || "",
        equipmentType: filter.equipmentType || "ALL",
        from: filter.from || null,
        to: filter.to || null,
        destination: filter.destination || "",
        origin: filter.origin || "",
        cargoType: filter.cargoType || "",
        commodity: filter.commodity || "",
        grade: filter.grade || "",
        lotNumber: filter.lotNumber || "",
        lastTracedLocation: filter.lastTracedLocation || "",
        servingYard: filter.servingYard || "",
        tracingStatus: filter.tracingStatus || "",
        equipmentSize: filter.equipmentSize || "",
        etaFrom: filter.etaFrom || null,
        etaTo: filter.etaTo || null,
        arrivalDateFrom: filter.arrivalDateFrom || null,
        arrivalDateTo: filter.arrivalDateTo || null,
        ...(filter.tracingStatus !== 'TRANSIT' ? { [filter.tracingStatus]: filter.tracingDate } : {})
        }
        const response = await axios.post(`${urlEquipmentService}/GetEquipmentByMovementType?${uri}`, filterData)
    return {
        params,
        data: response.data.data,
        totalPages: response.data.totalCount,
        filterData
    }
})

// Special Reports - Customer Code
export const getSpecialReport = async () => {
    const customerCode = JSON.parse(localStorage.getItem("userData")).companyCode
    const response = await axios.get(`${urlReportingService}/GetSpecialReport/${customerCode}`)
    if (response.data && response.data.totalCount > 0) {
        return {
            success: true,
            data: response.data.data
        }
    } else {
        return {
            success: false,
            message: 'Internal Server Error'
        }
    } 
}

// JTW Token
export const getIsValidToken = async () => {
    const response = await axios.get(`${urlAuthService}/IsValidToken`)
    return response.success
}

export const putRefreshToken = async () => {
    const response = await axios.get(`${urlAuthService}/RefreshToken`)
    return response.success
}

// Get Commodties
export const getCommodities = async () => {
    const cargoTypes = await axios.get(`${urlConfigCacheService}/CargoType`)
    const commodityTypes = await axios.get(`${urlConfigCacheService}/CommodityType`)
    const commodities = await axios.get(`${urlConfigCacheService}/CommodityName`)
    const commodityGrades = await axios.get(`${urlConfigCacheService}/CommodityGrade`)
    if (cargoTypes.data || commodityTypes.data || commodities.data || commodityGrades.data) {
        return {
            success: true,
            data: {
                cargoTypes: cargoTypes.data || [],
                commodityTypes: commodityTypes.data || [],
                commodities: commodities.data || [],
                commodityGrades: commodityGrades.data || []
            }
        }
    } else {
        return {
            success: false,
            message: 'Internal Server Error'
        }
    }
}

// Get Source/Destination
export const getRegionCity = async () => {
    const response = await axios.get(`${urlConfigCacheService}/GetAllSelect/RegionCity`)
    if (response.data) {
        return {
            success: true,
            data: response.data.data
        }
    } else {
        return {
            success: false,
            message: 'Internal Server Error'
        }
    }
}

export const customerDashboardSlice = createSlice({
    name: 'customerDashboard',
    initialState: { // Initial State for tabs
        transload: {
            data: [],
            total: 0,
            filter: {},
            params: {}
        },
        fforder: {
            data: [],
            total: 0,
            filter: {},
            params: {}
        },
        cargo: {
            data: [],
            total: 0,
            filter: {},
            params: {}
        },
        inventory: {
            data: [],
            total: 0,
            filter: {},
            params: {}
        },
        schedule: {
            data: [],
            total: 0,
            filter: {},
            params: {}
        },
        specialReportData: [],
        specialReportTotal: 0,
        total: 0,
        loaded: false
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getTransloadingList.fulfilled, (state, action) => {
            state.transload.data = action.payload.data
            state.total = action.payload.totalPages
            state.transload.filter = action.payload.filterData
            state.transload.params = action.payload.params
            state.loaded = true
        })
        .addCase(getFFOrderList.fulfilled, (state, action) => {
            state.fforder.data = action.payload.data
            state.total = action.payload.totalPages
            state.fforder.filter = action.payload.filterData
            state.fforder.params = action.payload.params
            state.loaded = true
        })
        .addCase(getCargoInventory.fulfilled, (state, action) => {
            state.cargo.data = action.payload.data
            state.total = action.payload.totalPages
            state.cargo.filter = action.payload.filterData
            state.cargo.params = action.payload.params
            state.loaded = true
        })
        .addCase(getMaterialInventory.fulfilled, (state, action) => {
            state.inventory.data = action.payload.data
            state.total = action.payload.totalPages
            state.inventory.filter = action.payload.filterData
            state.inventory.params = action.payload.params
            state.loaded = true
        })
        .addCase(getScheduleTerminalData.fulfilled, (state, action) => {
            state.schedule.data = action.payload.data
            state.total = action.payload.totalPages
            state.schedule.filter = action.payload.filterData
            state.schedule.params = action.payload.params
            state.loaded = true
        })
        builder.addMatcher(
            action => {
                return action.type.includes('/pending')
            },
            (state) => {
                state.loaded = false
            }
        )
    }
})

export default customerDashboardSlice.reducer