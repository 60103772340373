import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  jwtConfig = { ...jwtDefaultConfig }
  isAlreadyFetchingAccessToken = false
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request interceptor to attach the token to requests
    axios.interceptors.request.use(
      config => {
        const accessToken = this.getToken()
        if (accessToken) {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // Response interceptor to handle token expiration
    axios.interceptors.response.use(
      response => response,
      async error => {
        const { config, response } = error
        const originalRequest = config

        // Check if the error is due to an expired token (HTTP 401)
        if (response && response.status === 401 && !originalRequest._retry) {
          if (this.isAlreadyFetchingAccessToken) {
            // If a token refresh is already in progress, queue the request
            return new Promise(resolve => {
              this.addSubscriber(accessToken => {
                originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                resolve(axios(originalRequest))
              })
            })
          }

          // Mark the request to prevent infinite loops
          originalRequest._retry = true
          this.isAlreadyFetchingAccessToken = true

          try {
            // Refresh the token
            const res = await this.refreshToken()
            this.setToken(res.data.data.accessToken)
            this.setRefreshToken(res.data.data.refreshToken)

            // Update the original request with the new token
            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${res.data.data.accessToken}`

            // Retry the original request
            return axios(originalRequest)
          } catch (refreshError) {
            // If token refresh fails, clear user data and redirect to login
            this.clearUserData()
            window.location = '/login'
            return Promise.reject(refreshError)
          } finally {
            // Reset the flag and notify subscribers
            this.isAlreadyFetchingAccessToken = false
            this.onAccessTokenFetched(this.getToken())
          }
        }

        // For other errors, reject the promise
        return Promise.reject(error)
      }
    )
  }

  // Notify all queued requests with the new token
  onAccessTokenFetched(accessToken) {
    this.subscribers.forEach(callback => callback(accessToken))
    this.subscribers = []
  }

  // Add a request to the queue
  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  // Get the access token from localStorage
  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  // Get the refresh token from localStorage
  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  // Set the access token in localStorage
  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  // Set the refresh token in localStorage
  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  // Clear user data from localStorage
  clearUserData() {
    localStorage.removeItem('userData')
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  // Login method
  async login(...args) {
    const response = await axios.post(this.jwtConfig.loginEndpoint, ...args)
    const { accessToken, refreshToken } = response.data.data
    this.setToken(accessToken)
    this.setRefreshToken(refreshToken)
    return response
  }

  // Register method
  async register(...args) {
    const response = await axios.post(this.jwtConfig.registerEndpoint, ...args)
    return response
  }

  // Refresh token method
  async refreshToken() {
    const token = this.getToken()
    let refreshToken = this.getRefreshToken()
    // Remove "" from refreshToken
    refreshToken = refreshToken.replace(/^"(.+(?="$))"$/, '$1')

    const response = await axios.put(this.jwtConfig.refreshEndpoint, { token, refreshToken })
    return response
  }
}
